@font-face {
  font-family: 'Brandon Grotesque';
  src: local('BrandonGrotesque-Black'),
    url(./fonts/BrandonGrotesque/BrandonGrotesque-Black.otf) format('opentype');
}

#pms-integration-footer-actions {
  padding-right: 1em;
  padding-top: 1em;
}

#pms-integration-footer-actions .MuiButtonBase-root {
  font-size: 12px;
  padding: 6px 16px !important;
}

#pms-integration-footer-actions .MuiGrid-root {
  margin: 0 !important;
  padding: 0 !important;
  width: auto !important;
}
